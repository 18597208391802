
/*
	@include sc(pc) {
		width: 100%;
	}
	@include sc(sp) {
		width: 50%;
	}
*/
/*
	@include hover() {
		opacity: 0.6;
	}
*/
/*
	$line:イラレの行の値
	$size:イラレの文字の値

	@include lh(38, 15);
*/
/*
	$size:object-fitの値

	@include objectfit();
	@include objectfit('contain');
*/
/*
	@include taJ();
*/
/*
	@extend %rightarr;
*/
/*
Lora フォント設定
400：Regular（デフォルト）
700：Bold
*/
/*
Dejanire Headline フォント設定
300：Light
400：Regular（デフォルト）
500：Medium
*/
/*
	$color:色指定
	$stripe:線サイズ
	$spacing:線の空きサイズ
	$height:太さ

	@include dotted(#e6e1d2, 4,4,2);
*/
/*
example
@include dotted-y(#COLOR, WidthPX,SpaceingPX,LengthPX,); 横破線
@include dotted-y(#e6e1d2, 4,4,2,10);
*/
html {
  color: #303030;
  /*	@media print, screen and (min-width:1440px) and (max-width:1599px) {
  		font-size: 90px;
  	}
  	@media print, screen and (min-width:1366px) and (max-width:1439px) {
  		font-size: 85px;
  	}
  	@media print, screen and (min-width:1280px) and (max-width:1365px) {
  		font-size: 80px;
  	}
  	@media print, screen and (min-width:641px) and (max-width:1279px) {
  		font-size: 75px;
  	}*/
  /* SP */
}
html.not-ie {
  font-feature-settings: "palt";
}
@media print, screen and (min-width: 641px) {
  html {
    line-height: 1.5;
  }
}
@media screen and (max-width: 640px) {
  html {
    -webkit-text-size-adjust: 100%;
  }
}
@media screen and (min-width: 428px) and (max-width: 640px) {
  html {
    /* SP： 428px から 640pxの間 ※6.7inch(iPhone12ProMax) */
    font-size: 114px;
  }
}
@media screen and (min-width: 414px) and (max-width: 427px) {
  html {
    /* SP： 414px から 427pxの間 ※iPhone6 Plus・6s Plus・7 Plus・8 Plus・XR・11・XS Max・11 Pro Max */
  }
}
@media screen and (min-width: 375px) and (max-width: 413px) {
  html {
    /* SP： 375px から 413pxの間 ※iPhone6・6s・7・8・SE(2)・X・XS・11 Pro */
    /* 基本 */
  }
}
@media screen and (min-width: 360px) and (max-width: 374px) {
  html {
    /* SP： 360px から 374pxの間 ※小さめAndroid */
  }
}
@media screen and (max-width: 359px) {
  html {
    /* SP： 359px以下 ※iPhone 5・5s・SE(1) */
  }
}
html.edge {
  -ms-text-size-adjust: 100%;
}
html.os-mac {
  -webkit-font-smoothing: antialiased;
  /*@include sc(pc) {
  	font-feature-settings : "pkna";
  }*/
}
html.scrollingFlg {
  pointer-events: none;
}

/*body {
	position: relative;
	margin:0;
	padding:0;
	font-size: .11rem;
	@include sc(pc) {
		min-width: 1024px;
	}
}*/
.touchevents * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.chrome body {
  image-rendering: -webkit-optimize-contrast;
}

/*h1,h2,h3,h4,h5,h6,p,dl,dt,dd,ol,ul,li,td,th,figure {
	margin:0;
	padding:0;
}*/
article, aside, footer, header, main, nav, section, figcaption, figure {
  display: block;
}

img {
  -ms-interpolation-mode: bicubic;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

/*hr{
	height:0;
	overflow:visible;
	box-sizing: border-box;
}*/
b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

/*a {
	outline:0;
	text-decoration:none;
	color: inherit;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}*/
/*li {
	list-style:none;
}*/
/*sub,sup {
	position:relative;
	vertical-align:baseline;
	line-height: 0;
}*/
/*sub {
	bottom:-.25em;
}*/
/*sup {
	top:-.5em;
}*/
audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

canvas {
  display: inline-block;
}

input, select, button, optgroup, textarea {
  font-family: inherit;
  appearance: none;
  background: none;
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
}

button, input {
  overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button, select {
  cursor: pointer;
  text-transform: none;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border: none;
}

select::-ms-expand {
  display: none;
}

/* プリント対応 */
/*[data-hidePrintMessageArea] {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10px;
	pointer-events: none;
	opacity: 0;
}*/
/*@media print {
	body {
		position: relative;
		top: 100px;
	}
	body:before {
		content: "ページを印刷する際は一番下までスクロールして下さい。";
		display: none;
		white-space: pre;
		font-size: 20px;
		padding: 10px 10px;
		border: 3px solid #000;
		background-color: #FFF;
		color: #000;
		position: absolute;
		top: -100px;
		left: 0px;
		right: 0px;
		text-align: center;
		box-sizing: border-box;
	}
	.chrome body:before {
		content: "ページを印刷する際は一番下までスクロールして下さい。\A画像を表示させる場合は、「基本設定」→「背景のグラフィック」を有効にして下さい。";
	}
	.ie body:before {
		content: "ページを印刷する際は一番下までスクロールして下さい。\A画像を表示させる場合は、「印刷プレビュー」→「ページ設定」→「背景の色とイメージを印刷する」を有効にして下さい。";
	}
	.safari body:before {
		content: "ページを印刷する際は一番下までスクロールして下さい。\A画像を表示させる場合は、「詳細を表示」→「背景をプリント」を有効にして下さい。";
	}
	.edge body:before {
		content: "※Microsoft Edgeは背景や画像を印刷できません。";
	}
	.addPrintMessage body:before {
		display: block;
	}
	* {
		background-attachment:scroll!important;
	}
}*/
/* 721px以上は消す */
@media print, screen and (min-width: 641px) {
  [data-sc-sp] {
    display: none !important;
  }
}
/* 720px以下は消す */
@media screen and (max-width: 640px) {
  [data-sc-pc] {
    display: none !important;
  }
}
/*.mod_main {
}*/
/*.device-tablet .mod_main {
	width:calc(100% + 1px);
}*/
/* 自動高さ調整 */
[data-autoheight],
[data-pc-autoheight],
[data-sp-autoheight] {
  transition: none !important;
}

/* 遅延読み込み */
[data-lf] {
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s;
}

[data-lf][src],
[data-lf][style*=background-image],
[data-lf].load_view {
  opacity: 1;
}

.cmnTit01 {
  /*display: flex;
  justify-content: center;
  align-items: center;
  @include sc(pc) {
  	height: 3rem;
  	padding-top: 1.1rem;
  }
  @include sc(sp) {
  	height: 1.7rem;
  }
  > span {
  	@include f_dejanireheadline(300);
  	@include sc(pc) {
  		font-size: 0.6rem;
  		letter-spacing: 0.04rem;
  	}
  	@include sc(sp) {
  		font-size: 0.325rem;
  		letter-spacing: 0.02rem;
  	}
  }*/
}

.cmnTit02 {
  position: relative;
}
.cmnTit02 span {
  font-size: 20px;
  color: #c00;
}

/*.cassetteArea {
	.moduletit01 ,
	.freecontent h1 {
		color: #5CA5BE;
		border-bottom: 1px solid #5CA5BE;
		@include sc(pc) {
			font-size: 0.22rem;
			margin-top: 0.5rem;
			padding-bottom: 0.04rem;
			letter-spacing: 0.02rem;
		}
		@include sc(sp) {
			font-size: 0.20rem;
			margin-top: 0.4rem;
			padding-bottom: 0.1rem;
			letter-spacing: 0.02rem;
		}
	}
	.moduletit02 ,
	.freecontent h2 {
		@include sc(pc) {
			margin-top: 0.5rem;
			font-size: 0.18rem;
			padding-left: 22px;
			text-indent: -22px;
			letter-spacing: 0.02rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
			font-size: 0.17rem;
			padding-left: 22px;
			text-indent: -22px;
			letter-spacing: 0.02rem;
		}
		&:before {
			content: "";
			display: inline-block;
			background-color: #5CA5BE;
			@include sc(pc) {
				width: 14px;
				height: 14px;
				margin-right: 8px;
			}
			@include sc(sp) {
				width: 14px;
				height: 14px;
				margin-right: 8px;
			}
		}
	}
	.freecontent h3 ,
	.freecontent h4 ,
	.freecontent h5 ,
	.freecontent h6 {
		font-weight: bold;
		@include sc(pc) {
			margin-top: 0.5rem;
			font-size: 0.18rem;
			letter-spacing: 0.02rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
			font-size: 0.17rem;
			letter-spacing: 0.02rem;
		}
	}
	.moduletxt01 ,
	.freecontent p ,
	.freecontent blockquote ,
	.freecontent a ,
	.freecontent pre {
		@include sc(pc) {
			font-size: 0.15rem;
			margin-top: 0.20rem;
			line-height: 1.9;
			letter-spacing: 0.01rem;
		}
		@include sc(sp) {
			font-size: 0.135rem;
			margin-top: 0.20rem;
			line-height: 2.2;
			letter-spacing: 0.01rem;
		}
	}
	.freecontent a {
		text-decoration: underline;
		@include hover() {
			text-decoration: none;
		}
	}
	.freecontent pre {
		word-break: break-all;
		word-wrap: break-word;
		white-space: break-spaces;
	}
	.modulelist01 ,
	.freecontent ul {
		@include sc(pc) {
			margin-top: 0.4rem;
		}
		@include sc(sp) {
			margin-top: 0.3rem;
		}
		li {
			@include sc(pc) {
				font-size: 0.15rem;
				padding-left: 22px;
				text-indent: -22px;
				letter-spacing: 0.02rem;
				line-height: 1.8;
			}
			@include sc(sp) {
				font-size: 0.135rem;
				padding-left: 22px;
				text-indent: -22px;
				letter-spacing: 0.02rem;
				line-height: 1.8;
			}
			+ li {
				@include sc(sp) {
					margin-top: 0.1rem;
				}
			}
			&:before {
				content: "";
				display: inline-block;
				background-color: #5CA5BE;
				@include sc(pc) {
					width: 8px;
					height: 8px;
					margin-right: 14px;
					border-radius: 50%;
				}
				@include sc(sp) {
					width: 8px;
					height: 8px;
					margin-right: 14px;
					border-radius: 50%;
				}
			}
		}
	}
	.modulelist02 ,
	.freecontent ol {
		@include sc(pc) {
			margin-top: 0.4rem;
		}
		@include sc(sp) {
			margin-top: 0.3rem;
		}
		li {
			counter-increment: count;
			@include sc(pc) {
				font-size: 0.15rem;
				padding-left: 22px;
				text-indent: -22px;
				letter-spacing: 0.02rem;
				line-height: 1.8;
			}
			@include sc(sp) {
				font-size: 0.15rem;
				padding-left: 22px;
				text-indent: -22px;
				letter-spacing: 0.02rem;
				line-height: 1.8;
			}
			+ li {
				@include sc(sp) {
					margin-top: 0.1rem;
				}
			}
			&:before {
				content: counter(count);
				display: inline-block;
				color: #5CA5BE;
				text-indent: 0;
				@include sc(pc) {
					width: 8px;
					height: 8px;
					margin-right: 14px;
					font-size: 0.21rem;
					line-height: 1;
				}
				@include sc(sp) {
					width: 8px;
					height: 8px;
					margin-right: 14px;
					font-size: 0.21rem;
					line-height: 1;
				}
			}
		}
	}
	.freecontent img {
		display: block;
		max-width: 100%;
		height: auto;
		@include sc(pc) {
			margin-top: 0.5rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
		}
	}
	.modulelayout01 {
		@include sc(pc) {
			margin-top: 0.5rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
		}
		&:after {
			@include sc(pc) {
				content: "";
				display: block;
				clear: both;
			}
			@include sc(sp) {
			}
		}
		img {
			display: block;
			height: auto;
			@include sc(pc) {
				width: 47%;
				float: left;
			}
			@include sc(sp) {
				width: 100%;
			}
		}
		p {
			@include sc(pc) {
				font-size: 0.15rem;
				width: 47%;
				line-height: 1.9;
				letter-spacing: 0.01rem;
				float: right;
			}
			@include sc(sp) {
				font-size: 0.135rem;
				line-height: 2.1;
				letter-spacing: 0.01rem;
				margin-top: 0.2rem;
			}
		}
		&.type01 {
			img {
				@include sc(pc) {
					float: right;
				}
				@include sc(sp) {
				}
			}
			p {
				@include sc(pc) {
					float: left;
				}
				@include sc(sp) {
				}
			}
		}
	}
	.modulelayout02 {
		display: flex;
		justify-content: space-between;
		@include sc(pc) {
			margin-top: 0.5rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
		}
		.box {
			width: 47%;
			@include sc(pc) {
			}
			@include sc(sp) {
			}
		}
		img {
			display: block;
			width: 100%;
			@include objectfit();
			@include sc(pc) {
				height: 2.7rem;
			}
			@include sc(sp) {
				height: 1rem;
			}
		}
		p {
			@include sc(pc) {
				font-size: 0.15rem;
				line-height: 1.9;
				letter-spacing: 0.01rem;
				margin-top: 0.15rem;
			}
			@include sc(sp) {
				font-size: 0.11rem;
				line-height: 1.9;
				letter-spacing: 0.01rem;
				margin-top: 0.05rem;
			}
		}
		&.type01 {
			@include sc(sp) {
				display: block;
			}
			.box {
				@include sc(pc) {
					width: 30%;
				}
				@include sc(sp) {
					display: flex;
					justify-content: space-between;
					width: 100%;
				}
				+ .box {
					@include sc(sp) {
						margin-top: 0.2rem;
					}
				}
			}
			img {
				@include sc(pc) {
					height: 1.7rem;
				}
				@include sc(sp) {
					width: 46%;
					height: 0.9rem;
					@include objectfit();
				}
			}
			p {
				@include sc(pc) {
				}
				@include sc(sp) {
					width: 48%;
					margin-top: 0;
				}
			}
		}
	}
	.modulelayout03 {
		@include sc(pc) {
			display: flex;
			justify-content: space-between;
			margin-top: 0.5rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
		}
		.box {
			@include sc(pc) {
				display: flex;
				justify-content: space-between;
				width: 48%;
			}
			@include sc(sp) {
				display: flex;
				justify-content: space-between;
			}
			+ .box {
				@include sc(sp) {
					margin-top: 0.2rem;
				}
			}
		}
		img {
			display: block;
			@include sc(pc) {
				width: 38%;
				height: 1.1rem;
			}
			@include sc(sp) {
				width: 35%;
				height: 0.7rem;
				@include objectfit();
			}
		}
		p {
			@include sc(pc) {
				width: 58%;
				font-size: 0.15rem;
				line-height: 1.9;
				letter-spacing: 0.01rem;
			}
			@include sc(sp) {
				width: 60%;
				font-size: 0.11rem;
				line-height: 1.9;
				letter-spacing: 0.01rem;
			}
		}
	}
	.modulelayout04 {
		background-color: #EAF4F3;
		@include sc(pc) {
			margin-top: 0.5rem;
			padding: 0.35rem 0.4rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
			padding: 0.30rem 0.2rem;
		}
		dt {
			color: #5CA5BE;
			@include sc(pc) {
				font-size: 0.18rem;
				letter-spacing: 0.02rem;
			}
			@include sc(sp) {
				font-size: 0.17rem;
				letter-spacing: 0.02rem;
			}
		}
		dd {
			@include sc(pc) {
				margin-top: 0.15rem;
				font-size: 0.15rem;
				line-height: 2;
			}
			@include sc(sp) {
				margin-top: 0.15rem;
				font-size: 0.135rem;
				line-height: 2;
			}
		}
	}
	.modulelayout04a {
		background-color: #FFF;
		box-sizing: border-box;
		@include sc(pc) {
			margin-top: 0.8rem;
			padding: 0.45rem 0.55rem;
			border: 0.2rem solid #EAF4F3;
		}
		@include sc(sp) {
			margin-top: 0.8rem;
			padding: 0.25rem 0.25rem 0.3rem;
			border: 0.1rem solid #EAF4F3;
		}
		dt {
			color: #5CA5BE;
			@include f_dejanireheadline();
			@include sc(pc) {
				font-size: 0.30rem;
				letter-spacing: 0.02rem;
			}
			@include sc(sp) {
				font-size: 0.25rem;
				letter-spacing: 0.02rem;
				text-align: center;
			}
		}
		dd {
			@include sc(pc) {
				margin-top: 0.15rem;
				font-size: 0.15rem;
				line-height: 2;
			}
			@include sc(sp) {
				margin-top: 0.15rem;
				font-size: 0.135rem;
				line-height: 2.2;
			}
			&.right {
				text-align: right;
				@include sc(pc) {
					margin-top: 0.4rem;
				}
				@include sc(sp) {
				}
			}
		}
	}
	.modulelayout05 {
		@include sc(pc) {
			margin-top: 0.5rem;
		}
		@include sc(sp) {
			margin-top: 0.5rem;
		}
		dl {
			border-bottom: 1px solid #5CA5BE;
			@include sc(pc) {
				display: flex;
				padding: 0.2rem 0;
			}
			@include sc(sp) {
				padding: 0.20rem 0;
			}
			&:first-child {
				border-top: 1px solid #5CA5BE;
				@include sc(pc) {
				}
				@include sc(sp) {
				}
			}
		}
		dt {
			text-align: center;
			@include sc(pc) {
				width: 15%;
				font-size: 0.15rem;
			}
			@include sc(sp) {
				font-size: 0.135rem;
			}
		}
		dd {
			@include sc(pc) {
				width: 85%;
				font-size: 0.15rem;
			}
			@include sc(sp) {
				margin-top: 0.1rem;
				font-size: 0.135rem;
				line-height: 2;
			}
		}
	}
}
.cassetteNav {
	position: relative;
	@include sc(pc) {
		margin-top: 2rem;
		height: 0.5rem;
	}
	@include sc(sp) {
		margin-top: 0.5rem;
		height: 0.2rem;
	}
	&:before {
		@include sc(pc) {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #303030;
			top: -1rem;
		}
		@include sc(sp) {
		}
	}
	.navlink {
		position: relative;
		@include sc(pc) {
			width: 2.5rem;
			height: 0.5rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
		@include sc(sp) {
		}
		&:before ,
		&:after {
			@include sc(sp) {
				display: inline-block;
				font-size: 0.135rem;
				vertical-align: middle;
			}
		}
		&.prev {
			float: left;
			@include sc(pc) {
				padding-left: 0.55rem;
			}
			@include sc(sp) {
				&:after {
					content: "前の記事";
					background-color: #fff;
					position: relative;
					padding: 0 0.05rem;
					left: -0.15rem;
				}
			}
			svg {
				left: 0;
				@include sc(pc) {
				}
				@include sc(sp) {
				}
			}
		}
		&.next {
			float: right;
			@include sc(pc) {
				padding-right: 0.55rem;
			}
			@include sc(sp) {
				&:before {
					content: "次の記事";
					background-color: #fff;
					position: relative;
					padding: 0 0.05rem;
					right: -0.15rem;
				}
			}
			svg {
				right: 0;
				@include sc(pc) {
				}
				@include sc(sp) {
				}
			}
		}
		svg {
			fill: transparent;
			stroke: #000;
			stroke-width: 1px;
			overflow: visible;
			@include sc(pc) {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include sc(sp) {
				display: inline-block;
				vertical-align: middle;
			}
			> * {
				stroke-dashoffset: 0px;
				stroke-dasharray: 160;
			}
		}
		span {
			@include sc(pc) {
				display: block;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				font-size: 0.14rem;
				line-height: 1.9;
			}
			@include sc(sp) {
				display: none;
			}
		}

		//マウスオーバー
		svg > * {
			animation-name: infobtnarrow;
			animation-duration: 0.4s;
			animation-fill-mode: forwards;
			animation-timing-function:ease;
		}
		@keyframes infobtnarrow{
			0% {
				stroke-dashoffset: -160px;
			}
			100% {
				stroke-dashoffset: 0px;
			}
		}
		span {
			transition: 0.2s;
		}
		@include hover() {
			svg > * {
				animation-name: infobtnarrowhover;
				animation-duration: 0.4s;
			}
			span {
				opacity: 0.5;
			}
		}
		@keyframes infobtnarrowhover{
			0% {
				stroke-dashoffset: 0px;
			}
			100% {
				stroke-dashoffset: 160px;
			}
		}
	}
	.linkarchive {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		@include sc(pc) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.4rem;
			height: 0.5rem;
			margin: auto;
			background-color: #b3b3b3;
		}
		@include sc(sp) {
			width: 1rem;
			text-align: center;
		}
		span {
			position: relative;
			@include sc(pc) {
				color: #FFF;
				font-size: 0.15rem;
				letter-spacing: 0.01rem;
			}
			@include sc(sp) {
				font-size: 0.135rem;
			}
		}

		//マウスオーバー
		@include sc(pc) {
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #303030;

				animation-name: linkarchive;
				animation-duration: 0.3s;
				animation-fill-mode: forwards;
				animation-timing-function:ease;
			}
		}
		@keyframes linkarchive{
			0% {
				transform: scaleX(0);
				transform-origin: right top;
			}
			100% {
				transform: scaleX(1);
				transform-origin: right top;
			}
		}
		span {
			transition: 0.2s;
		}
		@include hover() {
			&:before {
				animation-name: linkarchivehover;
				animation-duration: 0.3s;
			}
			span {
				color: #000;
			}
		}
		@keyframes linkarchivehover{
			0% {
				transform: scaleX(1);
				transform-origin: left top;
			}
			100% {
				transform: scaleX(0);
				transform-origin: left top;
			}
		}
	}
}*/
.bestratearea {
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .bestratearea {
    padding: 0.9rem 0.5rem 1rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea {
    padding: 0.5rem 0 0.5rem;
  }
}
.bestratearea .deco {
  display: block;
  margin: auto;
  fill: #8E8851;
}
@media print, screen and (min-width: 641px) {
  .bestratearea .sublead {
    font-size: 0.23rem;
    margin-top: 0.15rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea .sublead {
    font-size: 0.175rem;
    margin-top: 0.08rem;
  }
}
.bestratearea .tit {
  font-family: dejanire-headline, "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 300;
}
@media print, screen and (min-width: 641px) {
  .bestratearea .tit {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea .tit {
    font-size: 0.4rem;
    margin-top: 0.05rem;
  }
}
.bestratearea .lead {
  display: inline-block;
  position: relative;
  background-color: #8E8851;
}
@media print, screen and (min-width: 641px) {
  .bestratearea .lead {
    padding: 0.03rem 0.15rem 0.01rem;
    margin-top: -0.1rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea .lead {
    padding: 0.01rem 0.1rem 0.01rem;
    margin-top: 0rem;
  }
}
.bestratearea .lead:before, .bestratearea .lead:after {
  content: "";
  display: block;
  height: 50%;
  position: absolute;
  right: 0;
  background-color: #8E8851;
  width: 10px;
}
.bestratearea .lead:before {
  top: 0;
  transform-origin: bottom right;
  transform: skewX(-30deg);
}
.bestratearea .lead:after {
  bottom: 0;
  transform-origin: top right;
  transform: skewX(30deg);
}
.bestratearea .lead span {
  color: #FFF;
}
@media print, screen and (min-width: 641px) {
  .bestratearea .lead span {
    font-size: 0.16rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea .lead span {
    font-size: 0.12rem;
  }
}
.bestratearea .lead span:before, .bestratearea .lead span:after {
  content: "";
  display: block;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #8E8851;
  width: 10px;
}
.bestratearea .lead span:before {
  top: 0;
  transform-origin: bottom left;
  transform: skewX(30deg);
}
.bestratearea .lead span:after {
  bottom: 0;
  transform-origin: top left;
  transform: skewX(-30deg);
}
@media print, screen and (min-width: 641px) {
  .bestratearea .txt {
    margin-top: 0.4rem;
    line-height: 2.5;
    font-size: 0.15rem;
  }
}
@media screen and (max-width: 640px) {
  .bestratearea .txt {
    margin-top: 0.2rem;
    line-height: 2.5;
    font-size: 0.11rem;
  }
}

.specialpresentarea {
  background-color: #EAF4F3;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea {
    padding: 0.9rem 0.5rem 2rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea {
    padding: 0.5rem 0.35rem 0.7rem;
  }
}
.specialpresentarea .tit {
  font-family: dejanire-headline, "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 300;
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .tit {
    font-size: 0.3rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .tit {
    font-size: 0.2rem;
  }
}
.specialpresentarea .lead {
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .lead {
    font-size: 0.15rem;
    margin-top: 0.05rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .lead {
    font-size: 0.135rem;
    margin-top: 0.15rem;
    line-height: 2.2;
  }
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxwrap {
    display: flex;
    justify-content: space-between;
    max-width: 11.1rem;
    margin: 0.5rem auto 0;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxwrap {
    margin-top: 0.25rem;
  }
}
.specialpresentarea .box {
  background-color: #fff;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .box {
    width: 5.25rem;
    box-sizing: border-box;
    padding: 0.45rem 0.4rem 0.4rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .box {
    padding: 0.25rem 0.2rem 0.3rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .box + .box {
    margin-top: 0.2rem;
  }
}
.specialpresentarea .boxtit {
  color: #BC5C83;
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxtit {
    font-size: 0.2rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxtit {
    font-size: 0.16rem;
  }
}
.specialpresentarea .boxtit svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  fill: #BC5C83;
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxtit svg {
    top: -1px;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxtit svg {
    top: -3px;
  }
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxdesc {
    margin-top: 0.25rem;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxdesc {
    margin-top: 0.14rem;
  }
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxdesctit {
    font-size: 0.18rem;
    line-height: 1.8;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxdesctit {
    font-size: 0.12rem;
    line-height: 1.8;
  }
}
@media print, screen and (min-width: 641px) {
  .specialpresentarea .boxdescnote {
    margin-top: 0.15rem;
    font-size: 0.14rem;
    line-height: 1.8;
  }
}
@media screen and (max-width: 640px) {
  .specialpresentarea .boxdescnote {
    margin-top: 0.15rem;
    font-size: 0.11rem;
    line-height: 2;
  }
}

.fairmodalarea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
}
.fairmodalarea.active {
  pointer-events: auto;
  opacity: 1;
}
.fairmodalarea .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.fairmodalarea .content {
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .content {
    max-width: 12.4rem;
    width: 100%;
    padding: 0.5rem 0.5rem 0.35rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .content {
    width: 90%;
    padding: 0.5rem 0.2rem 0.15rem;
  }
}
.fairmodalarea .close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  background-color: #fff;
  z-index: 10001;
  cursor: pointer;
  transition: 0.2s;
  transition: 0.2s;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .close {
    padding: 0.2rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .close {
    top: 0.2rem;
    right: 0.2rem;
  }
}
.fairmodalarea .close span {
  display: inline-block;
  padding: 0 0.05rem 0.02rem;
  font-family: dejanire-headline, "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #000;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .close span {
    font-size: 0.11rem;
  }
}
.no-touchevents .fairmodalarea .close:hover {
  opacity: 0.5;
}
.fairmodalarea .desc {
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .desc {
    font-size: 0.22rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .desc {
    font-size: 0.2rem;
  }
}
.fairmodalarea .table {
  border-top: 1px solid #BDB581;
  border-left: 1px solid #BDB581;
  border-collapse: collapse;
  width: 100%;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table {
    margin-top: 0.2rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .table {
    margin-top: 0.1rem;
  }
}
.fairmodalarea .table_tit {
  border-right: 1px solid #BDB581;
  border-bottom: 1px solid #BDB581;
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_tit {
    font-size: 0.2rem;
    padding: 0.2rem 0;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .table_tit {
    font-size: 0.14rem;
    padding: 0.1rem 0;
  }
}
.fairmodalarea .table_desc {
  border-right: 1px solid #BDB581;
  border-bottom: 1px solid #BDB581;
  display: table-cell;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_desc {
    padding: 0.3rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .table_desc {
    padding: 0.3rem 0.05rem;
  }
}
.fairmodalarea .table_desc.type02 {
  text-align: center;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_tit.type01,
.fairmodalarea .table_desc.type01 {
    width: 65%;
  }
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_tit.type02,
.fairmodalarea .table_desc.type02 {
    width: 35%;
  }
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_fairname {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.fairmodalarea .table_fairimg {
  display: block;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_fairimg {
    width: 1.3rem;
  }
}
.fairmodalarea .table_fairimg img {
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_fairimg img {
    height: 1.3rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .table_fairimg img {
    width: 0.8rem;
    height: 0.8rem;
    margin: auto;
  }
}
.fairmodalarea .table_fairtit {
  display: block;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .table_fairtit {
    font-size: 0.2rem;
    padding-left: 0.1rem;
    width: calc(100% - 1.5rem);
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .table_fairtit {
    margin-top: 0.1rem;
    font-size: 0.14rem;
  }
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .fairdate {
    display: inline-block;
    font-size: 0.18rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .fairdate {
    display: block;
    font-size: 0.16rem;
  }
}
.fairmodalarea .select {
  background-color: #5CA5BE;
  position: relative;
  transition: 0.2s;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .select {
    display: inline-block;
    margin-left: 0.2rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .select {
    display: block;
    text-align: center;
    margin-top: 0.1rem;
  }
}
.fairmodalarea .select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid #FFF;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.1rem;
  margin: auto;
}
.fairmodalarea .select select {
  color: #FFF;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .select select {
    font-size: 0.22rem;
    padding: 0.1rem 0.3rem 0.1rem 0.2rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .select select {
    font-size: 0.2rem;
    padding: 0.05rem;
  }
}
.fairmodalarea .select select option {
  color: #000;
}
.no-touchevents .fairmodalarea .select:hover {
  opacity: 0.5;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .reservebox {
    margin-top: 0.3rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .reservebox {
    margin-top: 0.2rem;
  }
}
.fairmodalarea .submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5CA5BE;
  margin: auto;
  cursor: pointer;
  transition: 0.2s;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .submit {
    width: 3rem;
    height: 0.6rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .submit {
    width: 2rem;
    height: 0.4rem;
  }
}
.fairmodalarea .submit span {
  color: #FFF;
}
@media print, screen and (min-width: 641px) {
  .fairmodalarea .submit span {
    font-size: 0.16rem;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .submit span {
    font-size: 0.15rem;
  }
}
.no-touchevents .fairmodalarea .submit:hover {
  background-color: #8E8851;
}
@media screen and (max-width: 640px) {
  .fairmodalarea .tel {
    display: block;
    text-align: center;
    width: 2rem;
    margin: 0.2rem auto 0;
    padding: 0.1rem 0;
  }
}
@media screen and (max-width: 640px) {
  .fairmodalarea .tel span {
    font-size: 0.16rem;
  }
}

.cmnBtn01 {
  display: inline-block;
  position: relative;
  padding: 0.1rem 0;
}
.cmnBtn01 span {
  font-family: dejanire-headline, "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
@media print, screen and (min-width: 641px) {
  .cmnBtn01 span {
    letter-spacing: 0.01rem;
    font-size: 0.18rem;
    margin-right: 0.1rem;
  }
}
@media screen and (max-width: 640px) {
  .cmnBtn01 span {
    letter-spacing: 0.01rem;
    font-size: 0.11rem;
    margin-right: 0.05rem;
  }
}
.cmnBtn01 span:before {
  content: "";
  display: block;
  border-radius: 50%;
  border: 1px solid #000;
  position: absolute;
  top: 0;
  bottom: 4px;
  margin: auto;
  width: 20px;
  height: 20px;
  right: -6px;
}
.cmnBtn01 svg {
  stroke: #000;
  stroke-width: 1px;
  fill: transparent;
  display: inline-block;
  vertical-align: middle;
}
.cmnBtn01 span:before {
  animation-name: cmnBtn01;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}
@keyframes cmnBtn01 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.no-touchevents .cmnBtn01:hover span:before {
  animation-name: cmnBtn01hover;
  animation-duration: 0.2s;
  animation-delay: 0.2s;
}
@keyframes cmnBtn01hover {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}

/*
[data-inlinemodalsimple02] {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
}
[data-inlinemodalsimple02-area] {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 10000;
    perspective: 500px;
    transition: 1.0s;
	&.active {
		opacity: 1;
		pointer-events: auto;
		.wrap {
			transform: scale(1.0) rotateX(0deg) translateY(-50%);
			opacity: 1;
		}
	}
	&.disabled {
		pointer-events: none;
	}
	&.modalone {
		.pager ,
		.prev ,
		.next {
			display: none;
		}
	}
	.close {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		cursor: pointer;
		z-index: 10001;
	}
	.wrap {
		max-width: 800px;
		position: absolute;
		top: 50%;
		left: 50px;
		right: 50px;
		margin: auto;
		background-color: #fff;
		z-index: 10001;
		transform-style: preserve-3d;
		padding: 44px;
		box-sizing: border-box;
		width: 100%;

		transform: scale(0.7) rotateX(10deg) translateY(-50%);
		opacity: 0;
		transition: 1.0s;
		@include sc(sp) {
			width: 80%;
			left: 10px;
			right: 10px;
			padding: 44px 10px;
		}
	}
	.content {
		position: relative;
		z-index: 10001;
		background-color: #fff;
		[data-inlinemodalsimple-inline] {
			position: relative;
			opacity: 0;
			pointer-events: none;
			overflow: hidden;
			display: none;
			&.active {
				display: block;
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	.prev ,
	.next {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 44px;
		height: 44px;
		cursor: pointer;
		z-index: 10001;
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 40px;
			height: 40px;
			background-color: #FFF;
			border-radius: 50%;
			transition: 0.2s;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 6px;
			height: 6px;
			margin: auto;
			transition: 0.2s;
		}
		.no-touchevents &:hover {
			&:before {
				background-color: #000;
			}
			&:after {
				border-color: #FFF;
			}
		}
	}
	.prev {
		left: -22px;
		&:after {
			left: 5px;
			border-left: 2px solid #000;
			border-bottom: 2px solid #000;
			transform: rotate(45deg);
		}
	}
	.next {
		right: -22px;
		&:after {
			right: 5px;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			transform: rotate(-45deg);
		}
	}
	.pager {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		z-index: 10001;
		button {
			width: 40px;
			height: 40px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			&:after ,
			&:before {
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				background-color: #DDD;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				border-radius: 50%;
				@include sc(sp) {
					width: 6px;
					height: 6px;
				}
			}
			&:after {
				transition: .2s;
			}
			.no-touchevents &:hover,
			&.active {
				&:after {
					background-color: #000;
				}
			}
			&.active {
				cursor: default;
				pointer-events: none;
			}
		}
	}
	.contentclose {
		position: absolute;
		top: 0.2rem;
		right: 0.2rem;
		background-color: #fff;
		z-index: 10001;
		cursor: pointer;
		transition: 0.2s;
		@include sc(pc) {
			padding: 0.2rem;
		}
		@include sc(sp) {
			top: 0.2rem;
			right: 0.2rem;
		}
		span {
			display: inline-block;
			padding: 0 0.05rem 0.02rem;
			@include f_dejanireheadline();
			border-bottom: 1px solid #000;
			@include sc(pc) {
				font-size: 0.11rem;
			}
			@include sc(sp) {
			}
		}

		//マウスオーバー
		transition: 0.2s;
		@include hover() {
			opacity: 0.5;
		}
	}
}
*/
[data-pararax01] {
  position: relative;
}

[data-pararax01-inview] {
  position: absolute !important;
  top: 120px;
  bottom: 120px;
  left: 0;
  right: 0;
  width: 0%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  opacity: 0;
}
@media print, screen and (min-width: 641px) and (max-height: 800px) {
  [data-pararax01-inview] {
    top: 120px;
    bottom: 120px;
  }
}
@media print, screen and (min-width: 641px) and (max-height: 650px) {
  [data-pararax01-inview] {
    top: 50px;
    bottom: 50px;
  }
}
@media screen and (max-width: 640px) {
  [data-pararax01-inview] {
    top: 100px;
    bottom: 100px;
  }
}

[data-pararax01-opacity01] {
  opacity: 0 !important;
}
[data-pararax01-opacity01].active {
  opacity: 1 !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity02] {
  opacity: 0 !important;
  transform: translateX(-20px) !important;
}
[data-pararax01-opacity02].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity03] {
  opacity: 0 !important;
  transform: translateX(20px) !important;
}
[data-pararax01-opacity03].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity04] {
  opacity: 0 !important;
  transform: translateY(20px) !important;
}
[data-pararax01-opacity04].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity05] {
  opacity: 0 !important;
  transform: translateY(-20px) !important;
}
[data-pararax01-opacity05].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity06] {
  opacity: 0 !important;
  transform: scale(0.8) !important;
}
[data-pararax01-opacity06].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity07] {
  opacity: 0 !important;
  transform: scale(1.2) !important;
}
[data-pararax01-opacity07].active {
  opacity: 1 !important;
  transform: none !important;
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity08] {
  perspective: 1000px;
}
[data-pararax01-opacity08] [data-pararax01-opacity08-target] {
  transform-style: preserve-3d;
  opacity: 0;
  transform: rotateY(720deg) scale(0.2);
  overflow: hidden;
}
[data-pararax01-opacity08] [data-pararax01-opacity08-target]:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0% 100%);
}
[data-pararax01-opacity08].active [data-pararax01-opacity08-target] {
  opacity: 1;
  transform: rotateY(0deg) scale(1);
  transition: 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
[data-pararax01-opacity08].active [data-pararax01-opacity08-target]:after {
  left: 100%;
  transition: 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.7s;
  clip-path: polygon(50px 0%, 100% 0%, 100% 100%, 0% 100%);
}

[data-pararax01-opacity09] {
  clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
  opacity: 0;
}
[data-pararax01-opacity09].active {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  opacity: 1;
  transition: clip-path 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity10] {
  clip-path: polygon(0% 0%, 100% -100%, -100% 100%);
  opacity: 0;
}
[data-pararax01-opacity10].active {
  clip-path: polygon(0% 0%, 200% 0%, 0% 200%);
  opacity: 1;
  transition: clip-path 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity11] {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  opacity: 0;
}
[data-pararax01-opacity11].active {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  opacity: 1;
  transition: clip-path 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-pararax01-opacity12] {
  clip-path: polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%);
  opacity: 0;
}
[data-pararax01-opacity12].active {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  opacity: 1;
  transition: clip-path 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 1.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

/*
//activeというclassがつくだけ
[data-pararax01-setclass] {
}

.pararax01_pattern01 {
	font-size: 20px;
	line-height: 2;
	font-weight: bold;
	.pararax01_pattern01_underline {
		background-image: linear-gradient(#f00 0%, #f00 100%);
		background-position: left bottom;
		background-repeat: no-repeat;
		background-size: 0% 2px;
	}
	&.active {
		.pararax01_pattern01_underline {
			background-size: 100% 2px;
			transition: background-size $datapararax01time*2 cubic-bezier(0.2, 0, 0.18, 1);
		}
	}
}
[data-pararax01-photo01a] ,
[data-pararax01-photo01b] ,
[data-pararax01-photo02a] ,
[data-pararax01-photo02b] ,
[data-pararax01-photo03a] ,
[data-pararax01-photo03b] ,
[data-pararax01-photo03c] ,
[data-pararax01-photo04a] ,
[data-pararax01-photo04b] {
	display: inline-block;
	position: relative;
	opacity: 0;
	font-size: 0;
	.ie & > * {
		opacity: 0;
	}
}
[data-pararax01-photo01a].active ,
[data-pararax01-photo01b].active ,
[data-pararax01-photo02a].active ,
[data-pararax01-photo02b].active ,
[data-pararax01-photo03a].active ,
[data-pararax01-photo03b].active ,
[data-pararax01-photo03c].active ,
[data-pararax01-photo04a].active ,
[data-pararax01-photo04b].active {
	opacity: 1;
	.ie & > * {
		opacity: 1;
	}
}
.ie [data-pararax01-photo01a] ,
.ie [data-pararax01-photo01b] ,
.ie [data-pararax01-photo02a] ,
.ie [data-pararax01-photo02b] ,
.ie [data-pararax01-photo03a] ,
.ie [data-pararax01-photo03b] ,
.ie [data-pararax01-photo03c] ,
.ie [data-pararax01-photo04a] ,
.ie [data-pararax01-photo04b] ,
.edge [data-pararax01-photo01a] ,
.edge [data-pararax01-photo01b] ,
.edge [data-pararax01-photo02a] ,
.edge [data-pararax01-photo02b] ,
.edge [data-pararax01-photo03a] ,
.edge [data-pararax01-photo03b] ,
.edge [data-pararax01-photo03c] ,
.edge [data-pararax01-photo04a] ,
.edge [data-pararax01-photo04b] {
	background-color: #FFF;
	opacity: 1 !important;
}
[data-pararax01-photo01a] svg ,
[data-pararax01-photo01b] svg ,
[data-pararax01-photo02a] svg ,
[data-pararax01-photo02b] svg ,
[data-pararax01-photo03a] svg ,
[data-pararax01-photo03b] svg ,
[data-pararax01-photo03c] svg ,
[data-pararax01-photo04a] svg ,
[data-pararax01-photo04b] svg {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}
[data-pararax01-photo01a] rect ,
[data-pararax01-photo01b] rect ,
[data-pararax01-photo02a] rect ,
[data-pararax01-photo02b] rect ,
[data-pararax01-photo03a] rect ,
[data-pararax01-photo03b] rect ,
[data-pararax01-photo03c] rect ,
[data-pararax01-photo04a] rect ,
[data-pararax01-photo04b] rect {
	fill: #FFF;
}
[data-pararax01-photo01a].init ,
[data-pararax01-photo01b].init ,
[data-pararax01-photo02a].init ,
[data-pararax01-photo02b].init ,
[data-pararax01-photo03a].init ,
[data-pararax01-photo03b].init ,
[data-pararax01-photo03c].init ,
[data-pararax01-photo04a].init ,
[data-pararax01-photo04b].init {
	opacity: 1;
}


[data-inviewtextanimation] {
	position: relative;
}
[data-inviewtextanimation-inview]{
	position: absolute;
	top: 120px;
	bottom: 120px;
	left: 0;
	width: 100%;
	pointer-events: none;
	opacity: 0;
	@include sc(pc) {
		@media print,screen and (max-height:800px) {
			top: 120px;
			bottom: 120px;
		}
		@media print,screen and (max-height:650px) {
			top: 50px;
			bottom: 50px;
		}
	}
	@include sc(sp) {
		top: 100px;
		bottom: 100px;
	}
}
[data-inviewtextanimation-char]{
	display: inline-block;
}

//activeというclassがつくだけ
[data-inviewtextanimation-textanimation01] ,
[data-inviewtextanimation-textanimation02] ,
[data-inviewtextanimation-textanimation03] ,
[data-inviewtextanimation-textanimation04] ,
[data-inviewtextanimation-textanimation05] ,
[data-inviewtextanimation-textanimation06] ,
[data-inviewtextanimation-textanimation07] ,
[data-inviewtextanimation-textanimation08] ,
[data-inviewtextanimation-textanimation09] ,
[data-inviewtextanimation-textanimation10] ,
[data-inviewtextanimation-textanimation11] ,
[data-inviewtextanimation-textanimation12] {
	transition: none!important;
	opacity: 0;
}
*/